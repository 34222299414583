import {Injectable} from '@angular/core';
import {EnterPromotionContactData, PublicSeminarData, SessionTrackingData} from './generated/cronos/data';
import {PublicFormatPageData} from './generated/cms/data';
import {
  DecoratorOpts,
  LocalStorageService as NgxLocalStorageService,
  StorageEventService,
  StorageSerializer
} from 'ngx-localstorage';
import {filter} from 'rxjs/operators';

/**
 * Provides a default serialization mechanism while
 */
@Injectable()
export class DefaultSerializer implements StorageSerializer {
  /**
   * @inheritdoc
   */
  public serialize(value: any): string {
    return JSON.stringify(value);
  }

  /**
   * @inheritdoc
   */
  public deserialize(storedValue: string): any {
    return JSON.parse(storedValue);
  }
}

/**
 * Constructs the storage key based on a prefix - if given - and the key itself
 */
export const constructKey = (key: string, prefix?: string, configuredPrefix?: string): string => {
  const prefixToUse = prefix || configuredPrefix;
  if (prefixToUse) {
    return `${prefixToUse}_${key}`;
  }
  return key;
}

/**
 * Provides a decoarator to bind a property directly to a storage value.
 * @param options configuration used for the decoarator
 */
export function ngxLocalStorage(options?: DecoratorOpts) {
  return function (target: Object, propertyDescription: string) {

    const key = !!options && !!options.key ? options.key : propertyDescription;
    const prefix = !!options && !!options.prefix ? options.prefix : null;
    const storage = options?.storage ?? localStorage;

    const service: NgxLocalStorageService = new NgxLocalStorageService(new DefaultSerializer(),
      {
        prefix: prefix,
        storage: storage
      });


    const eventService: StorageEventService = new StorageEventService();
    eventService.stream.pipe(
      filter((ev: StorageEvent) => ev.key && ev.key.indexOf(constructKey(key, prefix)) >= 0)
    )
      .subscribe((ev: StorageEvent) => {
        if (!!ev.newValue && typeof ev.newValue === 'string') {
          if (ev.newValue !== 'null') {
            target[propertyDescription] = service.get(key, prefix);
          } else {
            target[propertyDescription] = options && !!options.nullTransformer ? options.nullTransformer() : null;
          }
        }
      });

    Object.defineProperty(target, propertyDescription, {
      get: function () {
        const storageValue = service.get(key, prefix);
        return storageValue == null && options && !!options.nullTransformer ? options.nullTransformer() : storageValue;
      },
      set: function (value: any) {
        if( key == 'localSplitActive') {
        }
        service.set(key, value, prefix);
      }
    });
  };
}

// Holds the parameters / campaign info from when user entered application. This info expires after some time
//Info outside of conversionwindow is considered non-existent
export class SessionTracking {
  constructor(
    public utm_source: string,
    public utm_content: string,
    public utm_medium: string,
    public utm_campaign: string,
    public itm_campaign?: string,
    public itm_adset?: string,
    public itm_creative?: string,
    public itm_mailsplit?: string,
    public itm_product?: string,
    public gclid?: string,
    public fbclid?: string,
    public cutm_adacc?: string,
    public occurred?: Date //gets set in push function
  ) {
  }


}
export function buildSessionTrackingData(data: SessionTracking): SessionTrackingData{
  return {
    platformIdentifier: data.utm_source ? data.utm_source.toString() : null,
    trafficMeasureIdentifier: data.utm_campaign ? data.utm_campaign.toString() : null,
    campaignContentIdentifier: data.utm_content ? data.utm_content.toString() : null,
    trafficMediumIdentifier: data.utm_medium ? data.utm_medium.toString() : null,
    productId: data.itm_product ? +data.itm_product : null,
    mailsplit: data.itm_mailsplit ? data.itm_mailsplit.toString() : null,
    adAccountIdentifier: data.cutm_adacc ? data.cutm_adacc.toString() : null,
    googleAdsCampaignId: +data.itm_campaign ? +data.itm_campaign : null,
    googleAdSetId: +data.itm_adset ? +data.itm_adset : null,
    googleAdsCreativeId: +data.itm_creative ? +data.itm_creative : null,
    gclid: data.gclid ? data.gclid.toString() : null,
    fbclid: data.fbclid ? data.fbclid.toString() : null,
    occurred: JSON.parse(JSON.stringify(data.occurred))
  }
}

//Remembers checkout configs until the user checked out successfully. Only remembers PER FORMAT, so if user selects new format, context is forgotten
export class CheckoutContext {
  constructor(
    public formatId: number, //referencing the cmsFormatId
    public promotion: any,
    public contextPage: PublicFormatPageData,
    public location: any) {
  }
}

export class GeoLocation {
  constructor(
    public longitude: number,
    public latitude: number
  ) {
  }
}

export class OnlineLectureAuth {
  constructor(
    public token: string
  ) {
  }
}

export class TripleOptInSuccessful {
  constructor(
    public success: boolean
  ) {
  }
}

export class EnterWebinarSplit {
  constructor(
    public webinarId: number,
    public splitIdentifier: string
    ) {
  }
}

const conversionWindowInDays = 7;


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() {
  }
  //Caution: Do not initally set any of the local storage items -> would be overriden on reinitialization of component
  //If default value is needed, make sure, you account for that in given getter function!
  @ngxLocalStorage() sessionTracking: SessionTracking[];

  @ngxLocalStorage() lectureAuthToken: OnlineLectureAuth;
  @ngxLocalStorage() tripleOptInSuccessful: TripleOptInSuccessful;

  @ngxLocalStorage() userGeo: boolean;
  @ngxLocalStorage() currentPage: PublicFormatPageData;
  @ngxLocalStorage() previousPage: PublicFormatPageData;
  @ngxLocalStorage() accountReferralCode: string = null;

  @ngxLocalStorage() referralCode: string;

  @ngxLocalStorage() shownAppPromoDialog: boolean = false

//new Checkout
  @ngxLocalStorage()
  private storedUserData: EnterPromotionContactData;

  //Not part of EnterPromotionContactData, so storing it seperately
  @ngxLocalStorage()
  locationName: string;

  @ngxLocalStorage()
  private currentCheckoutPage: PublicFormatPageData ;

  @ngxLocalStorage()
  selectedSeminar: PublicSeminarData;

  @ngxLocalStorage()
  enterWebinarSplit: EnterWebinarSplit

  //Old checkout
  @ngxLocalStorage() checkoutUserdata: EnterPromotionContactData;
  @ngxLocalStorage() checkoutContext: CheckoutContext;


  getSelectedSeminar(){
    return this.selectedSeminar
  }

  setSelectedSeminar(newValue){
    this.selectedSeminar = newValue
  }

   getstoredUserData(){
     return this.storedUserData
   }
   setstoredUserData(newValue){
     this.storedUserData = newValue
   }
  getlocationName(){
     return this.locationName
  }
  setlocationName(newValue){
     this.locationName = newValue
  }
   getcurrentCheckoutPage(){
     return this.currentCheckoutPage
   }
   setcurrentCheckoutPage(newValue){
     this.currentCheckoutPage = newValue
   }

  getSessionInfos(): SessionTracking[] {
    if(!this.sessionTracking) this.sessionTracking = []
    return this.sessionTracking
  }

  getLectureAuthToken(): string {
    return this.lectureAuthToken.token
  }

  setLectureAuthToken(token: string) {
    this.lectureAuthToken = {token: token};
  }

  getTripleOptInSuccessful(): boolean {
    return this.tripleOptInSuccessful.success
  }


  setTripleOptInSuccessful(success: boolean) {
    this.tripleOptInSuccessful = {success: success};
  }

  getEnterWebinarSplit(): EnterWebinarSplit {
    return this.enterWebinarSplit
  }

  setEnterWebinarSplit(data: EnterWebinarSplit) {
    this.enterWebinarSplit = data
  }

  getLastSessionInfoUpdate(): SessionTracking {
    if (!this.sessionTracking || !this.sessionTracking.length || !(this.sessionTracking instanceof Array)) {
      return null
    }
    return this.sessionTracking[this.sessionTracking.length - 1]
  }


  pushTrackedClick(data: SessionTracking) {
    if(!this.sessionTracking || !this.sessionTracking.length || !(this.sessionTracking instanceof Array)) {
      this.sessionTracking = [];
    }
    if (data != null && (data.utm_source || data.utm_medium || data.utm_campaign || data.utm_content ||
        data.itm_campaign || data.itm_adset || data.itm_creative || data.cutm_adacc || data.gclid || data.fbclid ||
        data.itm_mailsplit || data.itm_product)) {
      data.occurred = new Date();
      let changeObject = this.sessionTracking
      changeObject.push(data)
      this.sessionTracking = changeObject
      return
    }

  }


  setReferralCode(referralCode:string){
    this.referralCode = referralCode;
  }

  getReferralCode(){
    return this.referralCode;
  }

  setAccountReferralCode(accountReferralCode:string){
    this.accountReferralCode = accountReferralCode;
  }

  getAccountReferralCode(){
    return this.accountReferralCode;
  }

  getCheckoutUserData(): EnterPromotionContactData {
    if(!this.checkoutUserdata || this.checkoutUserdata == null) this.checkoutUserdata = <EnterPromotionContactData>{}
    return this.checkoutUserdata;
  }

  updateCheckoutUserData(data: EnterPromotionContactData) {
    //Update fields that are provided, keep old existing fields
    let changeObject = this.getCheckoutUserData();
    if (data.firstName) {
      changeObject.firstName = data.firstName;
    }
    if (data.lastName) {
      changeObject.lastName = data.lastName
    }
    if (data.email) {
      changeObject.email = data.email
    }
    if (data.phoneNumber) {
      changeObject.phoneNumber = data.phoneNumber
    }
    if (data.locationId) {
      changeObject.locationId = data.locationId
    }
    if (data.semester && data.semester.toString().length > 0) {
      changeObject.semester = data.semester
    } else {
      changeObject.semester = null;
    }
    if(data.studyAreaId) {
      changeObject.studyAreaId = data.studyAreaId
    }
    if(data.privacyConsentGiven) {
      changeObject.privacyConsentGiven = data.privacyConsentGiven
    } else changeObject.privacyConsentGiven = false
    this.checkoutUserdata = changeObject
  }

  getCurrentCheckoutContext(): CheckoutContext {
    return this.checkoutContext;
  }

  updateCheckoutContext(context: CheckoutContext) {
    this.checkoutContext = context;
  }

  getPreviousPage() : PublicFormatPageData {
    return this.previousPage;
  }

  updatePreviousPage(page: PublicFormatPageData) {
    this.previousPage = page;
  }

  updateCurrentPage(page: PublicFormatPageData) {
    this.updatePreviousPage(this.currentPage);
    this.currentPage = page;
  }

  getCurrentPage() : PublicFormatPageData {
    return this.currentPage;
  }

  resetCheckoutContext() {
    const newData = <CheckoutContext>{location: this.checkoutContext.location}
    this.checkoutContext = newData;
  }

  setGeoLocationGranted(granted: boolean) {
    this.userGeo = granted;
  }

  markAppPromoDialogAsShown(){
    this.shownAppPromoDialog = true
  }

  getAppPromoShown(): boolean {
    return this.shownAppPromoDialog
  }

  getGeoLocationGranted(): boolean {
    if(!this.userGeo) {
      this.userGeo = false
    }
    return this.userGeo;
  }
}
