import {Injectable} from '@angular/core';
import {SessionTracking} from "./local-storage.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  previousUrl: String;

  constructor(
    private router: Router,
    private route: ActivatedRoute) {
  }

  initPreviousUrlTracking() {
    this.router.events
      .subscribe(e => {
        if (e instanceof NavigationEnd) {
          this.previousUrl = e.url
        }
      });
  }

  isTrackingActive() {
    return this.route.snapshot.queryParams['utm_source'] || this.route.snapshot.queryParams['utm_medium'] || this.route.snapshot.queryParams['utm_content'] || this.route.snapshot.queryParams['utm_campaign']
  }


  generateUrlTailFromTracking(trackingData: SessionTracking, redirectUrl: string): string {
    if (!trackingData) return '';

    var paramPrefix =  '?';
    //If URL already has a param, we need to change the first param prefix to &
    if(redirectUrl.split('?').length > 1){
      paramPrefix = '&'
    }


    var res = '';

    if (trackingData.utm_medium) {
      res += paramPrefix + 'utm_medium=' + trackingData.utm_medium
      paramPrefix = '&'
    }

    if (trackingData.utm_campaign) {
      res += paramPrefix + 'utm_campaign=' + trackingData.utm_campaign
      paramPrefix = '&'
    }

    if (trackingData.utm_content) {
      res += paramPrefix + 'utm_content=' + trackingData.utm_content
      paramPrefix = '&'
    }

    if (trackingData.utm_source) {
      res += paramPrefix + 'utm_source=' + trackingData.utm_source
    }

    if (trackingData.cutm_adacc) {
      res += paramPrefix + 'cutm_adacc=' + trackingData.cutm_adacc
    }

    if (trackingData.gclid) {
      res += paramPrefix + 'gclid=' + trackingData.gclid
    }

    if (trackingData.itm_product) {
      res += paramPrefix + 'itm_product=' + trackingData.itm_product
    }

    if (trackingData.itm_mailsplit) {
      res += paramPrefix + 'itm_mailsplit=' + trackingData.itm_mailsplit
    }

    return res
  }


}
